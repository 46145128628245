<template>
  <main class="OrdersIncoming">
    <TheSubHeader
      icon="package-request"
      title="Recebimento de Físico"
      page-title-tag="Orders Incoming"
      type="filter"
      :actions="['back']"
      organizer-gap="1rem"
      organizer-columns="1fr 1fr 1fr 200px"
    >
      <template #filter>
        <!-- Lojista -->
        <BaseTreeSelect
          v-model="filters.storeLabel"
          name="Lojista"
          label="Lojista"
          placeholder="Selecione"
          :read-only="disableFields.store"
          :select-options="null"
          :fetch-options="'stores/fetchStoresAsync'"
          @input="filterStores"
        />

        <!-- Pesquisar Pedidos -->
        <BaseTreeSelect
          v-model="filters.queryType"
          name="Pesquisar pedidos por"
          label="Pesquisar pedidos por"
          placeholder="Selecione"
          :read-only="disableFields.type"
          :clearable="false"
          :auto-select="true"
          :select-options="incomingOrdersTypes"
        />

        <!-- Pedidos -->
        <InputGroup
          v-model="filters.query"
          label="Pedidos"
          :placeholder="querySearchLabel"
          :read-only="disableFields.query"
          :enable-bipping="true"
          :tooltip="tooltip"
          @configBiping="configBiping"
          @bipping="itsBipping = true, onInputBarcodeScanned($event)"
          @not-bipping="onCodeDefault"
          @print="handlerPrint"
          @input="onInputBarcodeScanned($event)"
        />

        <!-- Imprimir -->
        <BaseButton
          :label="printerConnected ? 'Imprimir' : 'Confirmar'"
          theme="icon-left"
          color="primary-base"
          filled
          :icon="printerConnected ? 'print' : 'package-request'"
          text-color="white"
          :disabled="disableFields.search"
          @click="confirmOrderIncoming()"
        />
      </template>
    </TheSubHeader>

    <!-- Content -->
    <div class="OrdersIncoming__Content">
      <!-- Table -->
      <div
        v-if="incomingOrders.data.content.length > 0"
        class="OrdersIncoming__Table"
      >
        <DataTable
          :header="incomingOrders.data.headers"
          :table="incomingOrders.data.content"
          is-sticky
        />
      </div>
      <div v-else class="OrdersIncoming__Empty">
        <p>
          Selecione um lojista e insira itens na pesquisa para exibir os pedidos
          que estão aguardando o recebimento.<br>
          Você pode inserir manualmente ou utilizar um leitor de código de
          barras.
        </p>
      </div>
    </div>

    <!-- Barcode Sanner Settings -->
    <BarcodeScannerSettings
      ref="barcodeScannerSettings"
      v-bind="currentProperties"
      :stores="storesToBarcodeSettings"
      @save="handlerSaveBarcodeScannerSetting"
      @restore="handlerRestoreBarcodeScannerSetting"
      @delete="handlerDeleteBarcodeScannerSetting"
    />
  </main>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import { TheSubHeader } from '@/components/organisms'
import { BaseTreeSelect, BaseButton } from '@/components/atoms'
import { DataTable, BarcodeScannerSettings, InputGroup } from '@/components/molecules'
import zebraPrint from '@/mixins/zebraPrint'
import barCodeScannerMixin from '@/mixins/barCodeScanner'
import beepingSoundMixin from '@/mixins/beepingSound'
import { orders } from '@/api'

export default {
  name: 'OrdersIncoming',
  components: {
    TheSubHeader,
    DataTable,
    BaseTreeSelect,
    BaseButton,
    InputGroup,
    BarcodeScannerSettings
  },
  mixins: [zebraPrint, barCodeScannerMixin, beepingSoundMixin],
  data() {
    return {
      filters: {
        storeLabel: null,
        queryType: null,
        query: null,
      },
    }
  },
  computed: {
    ...mapState({
      zebraPrinter: state => state.printer.zebraPrinter,
      printerConnected: state => state.printer.printerConnected,
    }),

    ...mapGetters({
      incomingOrdersTypes: 'orders/incomingOrdersTypes',
      storesWithoutParents: 'stores/storesWithoutParents',
      incomingOrders: 'orders/readyIncomingOrders',
    }),

    //Disable
    disableFields() {
      return {
        query: !this.filters.storeLabel,
        search:
          !this.filters.storeLabel ||
          !this.filters.queryType ||
          !this.filters.query,
      }
    },

    //Texts
    querySearchLabel() {
      return this.filters.queryType ? this.filters.queryType.name : ''
    },

  },

  mounted() {
    this.initZebra()
    this.setBarCodeRules({field: 'lojista'})
    this.setPrinterRules()
    this.fetchIncomingOrdersTypes()
  },

  methods: {
    //[INFO] - Barcode Settings Methods are insade mixin (barCodeScannerMixin)
    ...mapActions({
      confirmIncomingOrders: 'orders/confirmIncomingOrders',
      fetchIncomingOrdersTypes: 'orders/fetchIncomingOrdersTypes',
      setError: 'error/setError',
    }),

    //Callback BarcodeScanner
    onCodeDefault() {
      this.confirmOrderIncoming()
    },
    onCodeSliced(modelSliced) {
      this.filters.query = modelSliced
      this.confirmOrderIncoming()
    },

    //Handlers
    handlerPrint() {
      if (!this.printerConnected) {
        this.connectPrinter()
      } else {
        this.disconnectPrinter()
      }
    },

    filterStores(data) {
      this.filterBarcodeSettings(data)
    },

    //Send Confirm Order
    async confirmOrderIncoming() {

      const params = {
        store_id: this.filters.storeLabel.id,
        type_search_column: this.filters.query
          ? this.filters.queryType.id
          : null,
        type_search_value: this.filters.query,
      }

      await this.confirmIncomingOrders(params).then((res) => {
        this.barcodeBeepingCheck(true)

        if (this.zebraPrinter && this.printerConnected) {
          this.getZPLCode(res)
        }
      }).catch(() => {
        this.barcodeBeepingCheck(false)
      })

      //Reset
      this.resetFilter('query')
    },

    async getZPLCode(res) {

      //Check if barcode has value
      let barcode = this.filters.query
      if (!barcode) return
      

      let id = null
      //Check if response has an ID
      if (res.data && res.data.content && res.data.content[0] && res.data.content[0].id) {
        id = res.data.content[0].id
      }

      await orders.getZPLCode(id, (res) => {
        res.forEach(el => {
          if (this.printerReady) this.print(el)
        })
      }, (e) => e, () => this.setLoading(false))
       
    },

    //Reset
    resetFilter(filter) {
      this.filters[filter] = null
    },
  },
}
</script>

<style lang="scss" scoped>
.OrdersIncoming {
  &__Content {
    display: grid;
    grid-template-columns: 1fr minmax(min-content, 1180px) 1fr;
    grid-template-areas: '. main .';
    grid-column-gap: 1rem;
  }

  &__Table {
    grid-area: main;
    margin-bottom: 50px;
    position: relative;
  }

  &__Empty {
    grid-area: main;
    margin-bottom: 50px;

    p {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 15px 0;
      margin: 0;
      text-align: center;
      background: $gradient-primary;
    }
  }
}
</style>
